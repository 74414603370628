/** @flow
 * StratoDem Analytics : meet-blaise
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import { Link } from 'gatsby';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link as SmoothScrollLink } from "react-scroll/modules";

import { Layout, SEO, ToggleFullScreen } from '../components';
import { openHubSpotForm } from "../core/utils";
import HighIncome80PlusGrowthRate from '../images/HighIncome80PlusGrowthRate.gif';
import AustinVsDallasHouseholdIncome from '../images/AustinVsDallasHouseholdIncome.gif';
import AlphaSoutheastMarkets from '../images/AlphaSoutheastMarkets.gif';
import FloridaTopMarkets from '../images/FloridaTopMarkets.gif';
import TopGrowthMarketAreas from '../images/TopGrowthMarketAreas.gif';
import DiveDeepIntoMarket from '../images/DiveDeepIntoMarket.gif';
import CompareYourInvestmentPortfolio from '../images/CompareYourInvestmentPortfolio.png';

import './index.scss';
import './products/portfolio/core.scss';

const MeetBlaise = ({data}) => (
  <Layout containerClassName="define-a-market">
    <SEO
      title="Meet Blaise ML"
      description="Blaise ML is the StratoDem Research Suite machine learning analyst"
      keywords={['AI assistant', 'stratodem analytics', 'research', 'real estate', 'blaise ml', 'machine learning', 'real estate research']}
    />
    <div className="sd-section">
      <h3>Meet Blaise ML by StratoDem Analytics</h3>
      <p>
        Blaise ML is a Machine Learning analyst designed to provide clients
        desktop access to massive computational analysis for portfolio-level and
        property-level market analysis and forecasting.
      </p>
      <p>
        Blaise ML pulls exclusive data from the StratoDem Analytics Engine.
      </p>
      <div className="nav-buttons">
        <button
          className="btn-tertiary main"
          onClick={openHubSpotForm}
        >
          Request a demo of Blaise ML
        </button>

        <h4>Quick navigation</h4>
        <SmoothScrollLink
          to="ask-blaise-ml-about-markets"
          spy
          smooth
          duration={500}
          offset={-60}
        >
          <button className="btn-secondary">
            Ask about markets
          </button>
        </SmoothScrollLink>
        <SmoothScrollLink
          to="ask-blaise-ml-about-portfolios"
          spy
          smooth
          duration={500}
          offset={-60}
        >
          <button className="btn-secondary">
            Ask about portfolios
          </button>
        </SmoothScrollLink>
        <SmoothScrollLink
          to="what-else-can-i-ask"
          spy
          smooth
          duration={500}
          offset={-60}
        >
          <button className="btn-secondary">
            What else can I ask?
          </button>
        </SmoothScrollLink>
        <SmoothScrollLink
          to="how-to-work-with-blaise"
          spy
          smooth
          duration={500}
          offset={-60}
        >
          <button className="btn-secondary">
            Start with Blaise ML
          </button>
        </SmoothScrollLink>
      </div>
    </div>

    <div className="sd-section" id="ask-blaise-ml-about-markets">
      <h3>Ask Blaise ML about markets</h3>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Analyze multiple locations at once</h3>
          <p>
            Standard geographies—metros, counties, census tracts, and zip codes—can be selected
            and/or
            combined by Blaise ML as the basis for your analysis.
          </p>
          <p>
            <strong>For example: </strong>Compare the <strong>Austin-Round Rock, TX MSA </strong>
            to <strong>Dallas-Plano-Irving, TX </strong> to learn that Austin average household
            income growth has outpaced Dallas by over 4% the past five years.
          </p>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={AustinVsDallasHouseholdIncome}
                alt="Median household income in Austin, TX and Dallas, TX metros"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Identify top growth markets</h3>
          <p>
            Ask Blaise ML about top- or bottom-performing markets and discover how the selected
            location compares.
          </p>
          <p>
            <strong>For example: </strong>Of all markets: Which markets are forecast to have the
            greatest percentage growth of households age 80+ with household income of $100,000+ from
            2019 to 2024?
          </p>
          <p><strong>Blaise ML pulls the answer in seconds.</strong></p>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={HighIncome80PlusGrowthRate}
                alt="Top markets for growth of 80+ households with at least $100,000 in household income"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Compare future market outlook</h3>
          <p>
            Ask Blaise ML about forecast market conditions, market volatility, and demand driver
            growth for multifamily housing demand or senior housing (or other products).
          </p>
          <p>
            <strong>For example: </strong>Of all markets in the South Atlantic/Southeast division:
            Which markets have the highest alpha for economic performance relative to the US?
          </p>
          <p><strong>
            Blaise ML pulls the answer, restricted by region, state or metro, in
            seconds.</strong></p>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={AlphaSoutheastMarkets}
                alt="Top alpha markets for the Southeast region by economic growth"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>

    <div className="sd-section" id="ask-blaise-ml-about-portfolios">
      <h3>Ask Blaise ML about portfolios</h3>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Compare growth outlook across all properties</h3>
          <p>
            Upload a portfolio of properties locations and compare historical, current and forecast
            market conditions.
          </p>
          <p>
            <strong>For example: </strong>After uploading 700+ senior living properties, Blaise ML
            helps to frame the questions that matter by geographic region or set of
            properties &ndash;
            dramatically speeding up the research and due diligence process.
          </p>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={FloridaTopMarkets}
                alt="Top senior living markets in Florida for median household income growth for 80+ households"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Identify top growth markets</h3>
          <p>
            Get a deeper analysis of selected set of top- or bottom-performing properties powered by
            Blaise ML and the StratoDem Analytics engine.
          </p>
          <p>
            <strong>For example: </strong>Blaise ML analyzes and ranks your portfolio based on key
            trends such as:
          </p>
          <ul>
            <li>
              Divergence of growth rates for household income or net worth, picking out properties
              which have the strongest market dynamics
            </li>
            <li>
              Shifts in growth up or down, helping clients to track which markets are likeliest to
              be undervalued or overvalued
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={TopGrowthMarketAreas}
                alt="Top growth market areas for senior living operator by median household income for 80+ households"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Compare your investment portfolio</h3>
          <p>
            Compare your investment portfolio markets performance with:
          </p>
          <ul>
            <li>The US as a whole</li>
            <li>The largest REIT and investment portfolios</li>
            <li>Developer and operator portfolios</li>
            <li>Other real estate portfolios that StratoDem Analytics now tracks</li>
          </ul>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={CompareYourInvestmentPortfolio}
                alt="Compare your investment portfolio by median household income growth"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>
    <div className="sd-section">
      <Row>
        <Col md={6}>
          <h3>Dive deep into top properties</h3>
          <p>
            Drill down into the top- or bottom-performing properties you pull with Blaise ML.
          </p>
          <p>
            <strong>For example: </strong>After Blaise ML identifies a top ranking property, it can
            direct you to StratoDem Markets, where you can break
            down the market further into granular submarkets or segments by age, household income,
            net worth, home value, educational attainment, etc.
            Blaise ML uses maps, charts, heatmaps and written analysis to transform raw data from
            the
            StratoDem Analytics Engine into easy-to-share content (and data exports) for segments
            such as households 75 to 84 years old with household income between $25,000 and $75,000.
          </p>
        </Col>
        <Col md={6}>
          <ToggleFullScreen>
            <div className="custom-gif">
              <img
                style={{display: 'inline-block'}}
                src={DiveDeepIntoMarket}
                alt="Top alpha markets for the Southeast region by economic growth"
              />
            </div>
          </ToggleFullScreen>
        </Col>
      </Row>
    </div>

    <div className="sd-section" id="what-else-can-i-ask">
      <Row>
        <Col md={6}>
          <h3>What can you ask Blaise ML?</h3>
        </Col>
        <Col md={6}>
          <p>
            Blaise ML is trained to answer questions on any data in the StratoDem Analytics
            suite, including uploaded data:</p>
          <ul>
            <li>Which market areas will see the strongest growth of income-qualified senior
              households over the next three years?
            </li>
            <li>Of my properties on the West Coast, which are located in the most stable
              macroeconomic environments in 2019?
            </li>
            <li>Among my properties in the Austin metro area, where have home values increased the
              most for empty-nester homebuyers over the past five years?
            </li>
          </ul>
        </Col>
      </Row>
    </div>

    <div className="sd-section" id="how-to-work-with-blaise">
      <Row>
        <Col md={12}>
          <h3>How do I work with Blaise ML?</h3>
          <p>
            It's a four step process:
          </p>
          <h4>1. Upload or use a standard portfolio</h4>
          <p>
            Since Blaise ML analyzes portfolios of properties, you can either upload a
            batch of addressees or geographic definitions, or use standard portfolios such as:
            <ul>
              <li>200 - 1000 properties in the operating portfolios of the largest senior
                housing operators
              </li>
              <li>Hundreds of properties in the holdings of the largest senior living,
                healthcare, multifamily, or retail REITs
              </li>
              <li>Dozens of other real estate operator, developer, or investor portfolios
              </li>
            </ul>
          </p>
          <hr />
          <h4>2. Ask Blaise ML questions through the query bar</h4>
          <p>
            After uploading or choosing a portfolio definition, ask Blaise ML questions that you
            want to answer. For example:
            <ul>
              <li>Of my properties in Florida, which market areas will see the greatest slowdown in
                median household income growth for hosueholds age 80+ over the next five years?
              </li>
              <li>Which counties across the country have the highest concentration of high-income
                young adults?
              </li>
              <li>Which of my properties have seen the strongest growth in households entering
                prime-earning years over the past ten years?
              </li>
            </ul>
          </p>
          <hr />
          <h4>3. Let Blaise ML do the analysis</h4>
          <p>
            Then, let Blaise analyze all of your properties across Florida (or anywhere else) and
            compile rankings, highlights, and maps for you (with supporting data available for
            export).
          </p>
          <hr />
          <h4>4. Export or share the analysis</h4>
          <p>
            Use the rest of the StratoDem Research Suite to dive deeper into individual
            properties with <Link to="/products/stratodem-research-suite#markets">Markets</Link>,
            share with your team or investors via PDF or online, or
            download the data in Excel format and use it in your own models.
          </p>
        </Col>
      </Row>
    </div>

    <div className="sd-section call-to-action">
      <h3>Want more?</h3>
      <Link to="/products/stratodem-research-suite">
        <button className="btn-tertiary">
          Discover StratoDem Research Suite
        </button>
      </Link>
      <Link to="/solutions">
        <button className="btn-secondary">
          Explore solutions by sector
        </button>
      </Link>
    </div>

  </Layout>
);

export default MeetBlaise;
